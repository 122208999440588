import React from "react"
import { navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProductThumbnail = (props) => {

  const productData = props.data;
  const variant = productData.variants[0];
  const image = getImage(variant.product.images[0])

  return(
    <div className="product-thumbnail" onClick={ () => navigate(`/shop/products/${productData.handle}`) } >
      <GatsbyImage
        className="image"
        image={ image }
        alt={variant.product.images[0].altText ?? productData.title}
      />
      <p>
        <span className="title">{ productData.title }</span>
        <br/>
        ${variant.price }
      </p>
    </div>
  )
}

export default ProductThumbnail
