import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ProductThumbnail from "./product-thumbnail.js"

const ProductList = (props) => {
  // "data" prop is automatically generated from the graphql query.
  const data = useStaticQuery(graphql`
    query getProducts {
      allShopifyProduct(
        sort: {fields: [productType], order: DESC},
        filter: {tags: {eq: "one street"}}
      ) {
        edges {
          node {
            productType
            handle
            shopifyId
            title
            descriptionHtml
            variants {
              storefrontId
              shopifyId
              price
              product {
                images {
                  gatsbyImageData(placeholder: BLURRED, formats: WEBP, width: 400)
                  altText
                }
              }
            }
            status
          }
        }
      }
    }
  `);

  const productData = data.allShopifyProduct.edges;

  return (
    <div className="product-list">
    {
      productData.map(
        function(product) {
          if(product.node.productType==="Photo Print" || product.node.productType==="Postcard"){
            return(
              <ProductThumbnail 
                key={product.node.variants[0].id}
                data={product.node}
              />
            )
          } 
        }
      )
    }
    </div>
  )
}

export default ProductList