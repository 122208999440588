import * as React from "react";
import Layout from "../components/layout.js";
import ProductList from "../components/product-list.js";
import SEO from "../components/seo.js";

const Shop = (props) => {
  console.log(props);
  return (
    <Layout>
      <SEO title="Shop" />

      <main className="shop">
        <ProductList />
      </main>
    </Layout>
  );
};

export default Shop;
